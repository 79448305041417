/* App.css */
.main-container {
  text-align: center;
  padding: 20px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.todo-form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.todo-form .MuiTextField-root {
  margin-right: 10px;
  flex-grow: 1;
}

.todo-list {
  list-style-type: none;
  padding: 0;
}

.todo-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.completed {
  text-decoration: line-through;
}

.reset-button {
  margin-top: 20px;
  background-color: #007bff;
  color: white;
}

.export-button {
  margin-top: 10px;
  background-color: #28a745;
  color: white;
}

.footer {
  text-align: center;
  margin-top: 20px;
}

.footer-logo {
  width: 200px;
}
